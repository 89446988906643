import React, { useEffect, useState, useCallback } from 'react';
import Section from './components/Section';
import './App.css';
import { Element, scroller } from 'react-scroll';
import BackToTop from './components/BackToTop';
import debounce from 'lodash/debounce';
import { FaLinkedin, FaGithub } from 'react-icons/fa';
import Typed from 'typed.js'; // Import Typed.js

function App() {
  const [isScrolling, setIsScrolling] = useState(false);
  const sections = ['section1', 'section2', 'section3', 'section4', 'section5', 'section6', 'section7']; // Added section7 for languages
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);

  // Setup Typed.js for typing animation (for technologies section)
  useEffect(() => {
    const options = {
      strings: [
        'React',
        'Tailwind CSS',
        'Node.js',
        'Laravel',
        'PHP',
        'SQL',
        'HTML',
        'TypeScript',
        'JavaScript',
        'Python',
        'Craft CMS',
        'Statamic',
        'Adobe Creative Cloud',
        'Figma'
      ],
      typeSpeed: 50,
      backSpeed: 30,
      loop: true,
    };

    const typed = new Typed('.typed-tech-element', options);

    return () => {
      typed.destroy(); // Cleanup the effect on component unmount
    };
  }, []);

  const scrollToSection = (index) => {
    setIsScrolling(true);
    scroller.scrollTo(sections[index], {
      duration: 500,
      delay: 0,
      smooth: 'easeInOutQuart'
    });

    setTimeout(() => {
      setIsScrolling(false);
    }, 500);
  };

  const handleScroll = useCallback(
    debounce((event) => {
      if (isScrolling) return; // Prevent multiple scrolls at the same time

      if (event.deltaY > 0 && currentSectionIndex < sections.length - 1) {
        // Scroll down
        setCurrentSectionIndex(prev => prev + 1);
        scrollToSection(currentSectionIndex + 1);
      } else if (event.deltaY < 0 && currentSectionIndex > 0) {
        // Scroll up
        setCurrentSectionIndex(prev => prev - 1);
        scrollToSection(currentSectionIndex - 1);
      }
    }, 100), // Debounce delay of 100ms
    [currentSectionIndex, isScrolling, sections]
  );

  useEffect(() => {
    window.addEventListener('wheel', handleScroll, { passive: false });

    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, [handleScroll]);

  return (
    <div className="App">
      <Element name="section1">
        <Section title="Hi, I’m Ruben" />
      </Element>
      <Element name="section2">
        <Section title="I’m a Web Developer and Designer" />
      </Element>
      {/* Add new section for typing effect */}
      <Element name="section3">
        <Section
          title={
            <div className="technologies-section">
              <p>I work with:</p>
              <span className="typed-tech-element"></span> {/* This will display the typing effect */}
            </div>
          }
        />
      </Element>
      <Element name="section4">
        <Section title="Check out some of my work" />
      </Element>
      <Element name="section5">
          <Section
            title={
              <div className="project-container">
                <a 
                  href="https://train-planner-jet.vercel.app/" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="project-link"
                >
                  Train Planner
                </a>
                <p className="project-description">
                  A web application that allows you to conveniently plan your train trips.
                </p>
              </div>
            }
          />
        </Element>

        <Element name="section6">
          <Section
            title={
              <div className="project-container">
                <a 
                  href="https://up-next-task.vercel.app/" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="project-link"
                >
                  UpNextTask
                </a>
                <p className="project-description">
                  Yet another Todo app, featuring theme customization and more.
                </p>
              </div>
            }
          />
        </Element>
      <Element name="section7">
        <Section
          title={
            <div className="contact-container">
              <h1 className="contact-name">Ruben Redant</h1>
              <h2 className="contact-title">Web Developer/Designer</h2>
              <p className="contact-email">Contact me at ruben.redant147@gmail.com</p>
              <div className="contact-icons">
                <a 
                  href="https://www.linkedin.com/in/ruben-redant-932b931ba/" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="contact-icon"
                >
                  <FaLinkedin />
                </a>
                <a 
                  href="https://github.com/RedantRuben" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="contact-icon"
                >
                  <FaGithub />
                </a>
              </div>
            </div>
          }
        />
      </Element>
      <BackToTop />
    </div>
  );
}

export default App;
