// components/Section.js
import React from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

const Section = ({ title }) => {
  return (
    <div style={styles.section}>
      <motion.h1
        style={styles.title}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        {title}
      </motion.h1>
    </div>
  );
};

Section.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]).isRequired
};

const styles = {
  section: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'black', // Ensures all sections have a black background
    color: 'white',
  },
  title: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 600,
    fontSize: '28px',
    textAlign: 'center',
  },
};

export default Section;
